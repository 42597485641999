import { DarkThemeToggle } from "flowbite-react";

const products = [
  {
    id: 1,
    imageUrl: "https://cdn.builtbybit.com/carousel_images/1639/1639625-0c616a71bdab08f1a56c7beda8174ec9.jpg?variant=card",
    title: "Prime Status | Status Page v1.0-beta",
    price: "$5.50",
    discount: "Up to 38% off",
    rating: 0,
    reviews: 0,
    fastDelivery: true,
    bestPrice: true,
    link: 'https://builtbybit.com/resources/prime-status-status-page.51795/',
  },
  {
    id: 2,
    imageUrl: "https://cdn.builtbybit.com/carousel_images/1616/1616977-c249895cbb460e98d8c2c4b852e9bd5b.jpg?variant=card",
    title: "Fragify | Pre-designer v1.0",
    price: "$5.50",
    discount: "Up to 38% off",
    rating: 0,
    reviews: 0,
    fastDelivery: true,
    bestPrice: true,
    link: 'https://builtbybit.com/resources/fragify-pre-designer.49363/',
  },
  {
    id: 3,
    imageUrl: "https://cdn.builtbybit.com/carousel_images/1584/1584571-38729f1c6c8eceb3d033f9a1d06ac687.jpg?variant=card",
    title: "Voler | License dashboard | MySQL v1.0",
    price: "$5.50",
    discount: "Up to 38% off",
    rating: 0,
    reviews: 0,
    fastDelivery: true,
    bestPrice: true,
    link: 'https://builtbybit.com/resources/voler-license-dashboard-mysql.46010/',
  },
  {
    id: 4,
    imageUrl: "https://cdn.builtbybit.com/carousel_images/1577/1577114-3d03ccc939606d0054dea29eab828c04.jpg?variant=card",
    title: "Solary License | MySQL, JSON & MongoDB v1.1",
    price: "$5.50",
    discount: "Up to 38% off",
    rating: 5,
    reviews: 3,
    fastDelivery: true,
    bestPrice: true,
    link: 'https://builtbybit.com/resources/solary-license-mysql-json-mongodb.45207/',
  },
  {
    id: 5,
    imageUrl: "https://cdn.builtbybit.com/carousel_images/1575/1575564-5f7cb7df8e402d8a39ccef78adebbefa.jpg?variant=card",
    title: "Soroly | License Management FREE v1.0",
    price: "FREE",
    discount: "Up to 0% off",
    rating: 0,
    reviews: 0,
    fastDelivery: true,
    bestPrice: true,
    link: 'https://builtbybit.com/resources/soroly-license-management-free.44558/',
  },
  {
    id: 6,
    imageUrl: "https://cdn.builtbybit.com/carousel_images/1559/1559369-42febbb88c6b8c94edc1c0fa0ac87ada.jpg?variant=card",
    title: "Mia Accounts - For businesses v1.0",
    price: "$2.50",
    discount: "Up to 38% off",
    rating: 0,
    reviews: 0,
    fastDelivery: true,
    bestPrice: true,
    link: 'https://builtbybit.com/resources/mia-accounts-for-businesses.43195/',
  },
  {
    id: 7,
    imageUrl: "https://cdn.builtbybit.com/carousel_images/1636/1636071-9f08de41ccf1868e942824ae276b1b19.jpg?variant=card",
    title: "Mongo Manager | Manage mongodb easier v2.0-FIRE",
    price: "$5.50",
    discount: "Up to 38% off",
    rating: 0,
    reviews: 0,
    fastDelivery: true,
    bestPrice: true,
    link: 'https://builtbybit.com/resources/mongo-manager-manage-mongodb-easier.40545/',
  },
  {
    id: 8,
    imageUrl: "https://cdn.builtbybit.com/carousel_images/1576/1576649-cc199742ae148e50879fb21842554355.jpg?variant=card",
    title: "Atomic | Unique Litebans Website v1.0-beta",
    price: "$2.50",
    discount: "Up to 38% off",
    rating: 5,
    reviews: 1,
    fastDelivery: true,
    bestPrice: true,
    link: 'https://builtbybit.com/resources/atomic-unique-litebans-website.40180/',
  },
  {
    id: 9,
    imageUrl: "https://cdn.builtbybit.com/carousel_images/1576/1576648-887c653d65d5be65a4b5b7fcc8239f03.jpg?variant=card",
    title: "Blackout | 1 on Unique License dashboard v1.5.3",
    price: "$5.50",
    discount: "Up to 38% off",
    rating: 5,
    reviews: 5,
    fastDelivery: true,
    bestPrice: true,
    link: 'https://builtbybit.com/resources/blackout-1-on-unique-license-dashboard.38607/',
  },
];

function App() {
  return (
    <>
<header className="antialiased">
  <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
    <div className="flex flex-wrap justify-between items-center">
      <div className="flex justify-start items-center">
        <button id="toggleSidebar" aria-expanded="true" aria-controls="sidebar" className="hidden p-2 mr-3 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 1h14M1 6h14M1 11h7" /> </svg>
        </button>
        <button aria-expanded="true" aria-controls="sidebar" className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
          <svg className="w-[18px] h-[18px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 1h15M1 7h15M1 13h15" /></svg>
          <span className="sr-only">Toggle sidebar</span>
        </button>
        <a href="https://devbybit.com" className="flex mr-4">
          <img src="https://license.devbybit.com/parent/img/icon.png" className="mr-3 h-8" alt="DevByBit Logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">DevByBit</span>
        </a>
      </div>
      <div className="flex items-center lg:order-2">
        
        
        <DarkThemeToggle />
      </div>
    </div>
  </nav>
</header>




<section className="bg-white dark:bg-gray-900">
  <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
    <a href="https://devbybit.com/discord" className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700" role="alert">
      <span className="text-xs bg-primary-600 rounded-full text-white px-4 py-1.5 mr-3">New</span> 
      <span className="text-sm font-medium">DevByBit is out! See what's new</span> 
      <svg className="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
    </a>
    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">We invest in the world’s potential</h1>
    <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">Here at DevByBit we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p>
    <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
      <a href="https://devbybit.com/discord" className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
        Learn more
        <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
      </a>
    </div>
    
  </div>
</section>


<section className="bg-gray-50 py-8 antialiased dark:bg-gray-900 md:py-12">
  <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
    {/* Heading & Filters */}
    <div className="mb-4 items-end justify-between space-y-4 sm:flex sm:space-y-0 md:mb-8">
      <div>
        <h2 className="mt-3 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">Products</h2>
      </div>
      
    </div>
    <div className="mb-4 grid gap-4 sm:grid-cols-2 md:mb-8 lg:grid-cols-3 xl:grid-cols-4">

    {products.map(product => (
        <div key={product.id} className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-700 dark:bg-gray-800">
          <div className="h-40 w-full">
            <a href={product.link} target="_blank">
              <img className="mx-auto h-full dark:hidden rounded-lg" src={product.imageUrl} alt={product.title}/>
              <img className="mx-auto hidden h-full dark:block rounded-lg" src={product.imageUrl} alt={product.title}/>
            </a>
          </div>
          <div className="pt-6">
            <div className="mb-4 flex items-center justify-between gap-4">
              <span className="me-2 rounded bg-primary-100 px-2.5 py-0.5 text-xs font-medium text-primary-800 dark:bg-primary-900 dark:text-primary-300">
                {product.discount}
              </span>
              <div className="flex items-center justify-end gap-1">
                <a href={product.link} target="_blank" data-tooltip-target="tooltip-quick-look" className="rounded-lg p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <span className="sr-only">Quick look</span>
                  <svg className="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeWidth={2} d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                    <path stroke="currentColor" strokeWidth={2} d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  </svg>
                </a>
                <div id="tooltip-quick-look" role="tooltip" className="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700" data-popper-placement="top">
                  Quick look
                  <div className="tooltip-arrow" data-popper-arrow />
                </div>
                <button type="button" data-tooltip-target="tooltip-add-to-favorites" className="rounded-lg p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <span className="sr-only">Add to Favorites</span>
                  <svg className="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6C6.5 1 1 8 5.8 13l6.2 7 6.2-7C23 8 17.5 1 12 6Z" />
                  </svg>
                </button>
                <div id="tooltip-add-to-favorites" role="tooltip" className="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700" data-popper-placement="top">
                  Add to favorites
                  <div className="tooltip-arrow" data-popper-arrow />
                </div>
              </div>
            </div>
            <a href={product.link} target="_blank" className="text-lg font-semibold leading-tight text-gray-900 hover:underline dark:text-white">
              {product.title}
            </a>
            <div className="mt-2 flex items-center gap-2">
              <div className="flex items-center">
                {[...Array(product.rating)].map((_, i) => (
                  <svg key={i} className="h-4 w-4 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z" />
                  </svg>
                ))}
              </div>
              <p className="text-sm font-medium text-gray-900 dark:text-white">{product.rating}.0</p>
              <p className="text-sm font-medium text-gray-500 dark:text-gray-400">({product.reviews})</p>
            </div>
            <ul className="mt-2 flex items-center gap-4">
              {product.fastDelivery && (
                <li className="flex items-center gap-2">
                  <svg className="h-4 w-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
                  </svg>
                  <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Fast Delivery</p>
                </li>
              )}
              {product.bestPrice && (
                <li className="flex items-center gap-2">
                  <svg className="h-4 w-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 7V3m0 16v-4m-7 7h14m-7-4v3m-2-2v-3M7 12v6M9 12v6m4-12V3m0 0v2m-2-2v2m-3 2h4m2 0h4m-4 6h4m-4 0v3m0-3h-4m6 0h4m-4 6v3m4-3v-6M7 12v6M9 12v6M13 5v14" />
                  </svg>
                  <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Best Price</p>
                </li>
              )}
            </ul>
            <div className="mt-4 flex items-center justify-between gap-4">
              <span className="text-lg font-bold text-gray-900 dark:text-white">{product.price}</span>
              <a href={product.link} className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 text-center inline-flex items-center">
                Go Shopping
                <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      ))}
      
    </div>
  </div>
</section>



<section className="bg-white px-4 py-8 antialiased dark:bg-gray-900 md:py-16">
  <div className="mx-auto grid max-w-screen-xl rounded-lg bg-gray-50 p-4 dark:bg-gray-800 md:p-8 lg:grid-cols-12 lg:gap-8 lg:p-16 xl:gap-16">
    <div className="lg:col-span-5 lg:mt-0">
      <a href="#">
        <img className="mb-4 h-56 w-56 dark:hidden sm:h-96 sm:w-96 md:h-full md:w-full" src="https://i.pinimg.com/564x/36/8c/96/368c967df4cdb8e288b8cf19eed82707.jpg" alt="peripherals" />
        <img className="mb-4 hidden dark:block md:h-full" src="https://i.pinimg.com/564x/36/8c/96/368c967df4cdb8e288b8cf19eed82707.jpg" alt="peripherals" />
      </a>
    </div>
    <div className="me-auto place-self-center lg:col-span-7">
      <h1 className="mb-3 text-2xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-4xl">
      Commissions are open!
      </h1>
      <p className="mb-6 text-gray-500 dark:text-gray-400">Take advantage now of working with us with open commissions. Have your software tailored, professional and at an affordable price.</p>
      <a href="https://devbybit.com/discord" className="inline-flex items-center justify-center rounded-lg bg-primary-700 px-5 py-3 text-center text-base font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"> Pre-order now </a>
    </div>
  </div>
</section>


<footer className="p-4 bg-white sm:p-6 dark:bg-gray-800">
  <div className="mx-auto max-w-screen-xl">
    <div className="md:flex md:justify-between">
      <div className="mb-6 md:mb-0">
        <a href="https://devbybit.com/discord" className="flex items-center">
          <img src="https://license.devbybit.com/parent/img/icon.png" className="mr-3 h-8" alt="DevByBit Logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">DevByBit</span>
        </a>
      </div>
      <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
        <div>
          <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Resources</h2>
          <ul className="text-gray-600 dark:text-gray-400">
            <li className="mb-4">
              <a href="https://builtbybit.com/vuhp" className="hover:underline">BuiltByBit</a>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Follow us</h2>
          <ul className="text-gray-600 dark:text-gray-400">
            <li className="mb-4">
              <a href="https://github.com/jVuhp?tab=repositories" className="hover:underline ">Github</a>
            </li>
            <li>
              <a href="https://devbybit.com/discord" className="hover:underline">Discord</a>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
          <ul className="text-gray-600 dark:text-gray-400">
            <li className="mb-4">
              <a href="#" className="hover:underline">Privacy Policy</a>
            </li>
            <li>
              <a href="#" className="hover:underline">Terms &amp; Conditions</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    <div className="sm:flex sm:items-center sm:justify-between">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://devbybit.com/discord" className="hover:underline">DevByBit™</a>. All Rights Reserved.
      </span>
      <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" /></svg>
        </a>
      </div>
    </div>
  </div>
</footer>

    </>
  );
}

export default App;
